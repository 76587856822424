import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Sliders are often used for setting preferences, adjusting settings, or selecting numerical values.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/slider/slider-guideline-anatomy.svg",
      "alt": "anatomy button guideline"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Track`}</strong>{`: The horizontal or vertical line that represents the range of values.`}</li>
      <li parentName="ol"><strong parentName="li">{`Thumb/Handle`}</strong>{`: A draggable element that moves along the track to select a specific value.`}</li>
      <li parentName="ol"><strong parentName="li">{`Value Indicator`}</strong>{`: A tooltip or display that shows the selected value.`}</li>
      <li parentName="ol"><strong parentName="li">{`Minimum/Maximum Values`}</strong>{`: Numeric labels or indicators at the ends of the track, representing the range.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Slider component is used in web and application design for the following purposes:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Data Selection`}</strong>{`: Allowing users to select a value from a continuous range or set of options.`}</li>
      <li parentName="ul"><strong parentName="li">{`Setting Configuration`}</strong>{`: Adjusting settings such as volume, brightness, or preferences.`}</li>
      <li parentName="ul"><strong parentName="li">{`Filtering and Sorting`}</strong>{`: Filtering data within a specified range or sorting items by numerical criteria.`}</li>
    </ul>
    <p><strong parentName="p">{`Common places slider appear`}</strong>{` : Mobile app homepage , website homepage and other pages when needed etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>Users need to select a value from a range or a set of options.</li>
      <li>Precise control over a numerical value or setting is required.</li>
      <li>
        You want to provide a visually interactive and intuitive interface.
      </li>
      <li>
        Enhancing user experience and enabling fine-tuned adjustments is
        important.
      </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>
        Users do not need to select values within a range or set of options.
      </li>
      <li>
        Simpler input methods, like radio buttons or checkboxes, can achieve the
        same goal.
      </li>
      <li>
        The precision of the selection is not a critical aspect of the
        interaction.
      </li>
      <li>Sliders add unnecessary complexity to the user interface.</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`To ensure accessibility, consider the following:`}</p>
    <ul>
      <li parentName="ul">{`Make sure that the slider is keyboard accessible.`}</li>
      <li parentName="ul">{`Provide clear and concise labels for the slider.`}</li>
      <li parentName="ul">{`Add an aria-label attribute to the slider to provide a descriptive label for screen reader users.`}</li>
      <li parentName="ul">{`Use the aria-valuemin and aria-valuemax attributes to indicate the minimum and maximum values of the slider to screen reader users.`}</li>
      <li parentName="ul">{`Use the aria-valuenow attribute to indicate the current value of the slider to screen reader users.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Make sure that the slider track is high enough contrast to be easily visible." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/slider/slider-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="The slider track is not high enough contrast to be easily visible." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/slider/slider-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <br />
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Include clear and informative labels for minimum and maximum values." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/slider/slider-a11y-do2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Didn’t Include clear and informative labels for minimum and maximum values." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/slider/slider-a11y-dont2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When implementing Slider content, follow these guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Clearly label the minimum and maximum values.`}</li>
      <li parentName="ul">{`Include numeric labels for intervals or ticks if applicable.`}</li>
      <li parentName="ul">{`Provide tooltips or value indicators to display the selected value.`}</li>
      <li parentName="ul">{`Ensure that the Slider aligns with the context and purpose of its use in the interface.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      